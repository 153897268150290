<template>
<div class="fixed inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 sm:rounded-lg">

        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div class="w-full max-w-xs sm:max-w-none h-64 sm:w-96 inline-block align-bottom rounded-lg 
        transform transition-all sm:my-8 sm:align-middle bg-white text-center">
            <div class="grid grid-cols-1 h-full">
                <div class="justify-self-center self-end">
                    <img src="../assets/img/anillo_doble_1.6s_150px.gif" 
                    alt="" width="150" height="113"/>
                </div>

                <div class="sm:px-6">
                    <p class="px-8 text-center text-lg text-gray-700">
                        {{ fraseCarga }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { obtenerRandomEntero } from '@/utils/helpers'

export default {
    setup() {
        const frases = [
            'Hola...',
            'Un momento...',
            'Un momentito...',
            'Espera...',
            'Ahí vamos...'
        ];

        return {
            frases
        }
    },
    data() {
        return {
            numeroFraseSeleccionada: 0
        }
    },
    computed: {
        fraseCarga: function() {
            return this.frases[this.numeroFraseSeleccionada];
        }
    },
    mounted() {
        let numeroAleatorio = obtenerRandomEntero(0, this.frases.length);
        this.numeroFraseSeleccionada = numeroAleatorio;
    },
    props: {
        mensaje: {
            type: String,
            default: 'Cargando...'
        }
    }
}
</script>